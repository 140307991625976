/*!
=========================================================
* Muse Ant Design Dashboard PRO - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

@media (min-width: 992px) {
  .layout-dashboard .ant-layout-footer {
    margin: 0 0 20px 20px;
  }
  .layout-dashboard .ant-layout-sider.sider-primary {
    /* margin: 20px 0 0 20px; */
    padding: 13px 20px;
    margin: 0 !important;
    height: 100vh !important;
    /* height: calc(100vh - 20px); */
  }
  .layout-dashboard .ant-layout {
    width: auto;
    flex-shrink: 1;
    margin-left: 250px;
  }
  .layout-dashboard .header-control .sidebar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .layout-dashboard .ant-layout-header {
    margin: 10px 20px;
  }
  .layout-dashboard .header-control {
    margin-top: 0;
  }
  .layout-dashboard .header-control .header-search {
    margin: 0 7px;
  }
  .layout-dashboard .header-control .btn-sign-in span {
    display: inline;
  }
  .profile-nav-bg {
    margin-top: -87.8px;
  }
  .card-profile-head {
    margin: -53px 24px 24px;
  }
}

@media (min-width: 992px) {
  .card-billing-info.ant-card .ant-card-body {
    display: flex;
  }
  .layout-dashboard-rtl {
    overflow: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    margin: 20px 20px 0 0;
    height: calc(100vh - 20px);
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout {
    margin-right: 250px;
    margin-left: 0;
  }
  .layout-dashboard-rtl .ant-layout-footer {
    margin: 0 20px 20px 0;
  }
}

@media (max-width: 768px) {
  .layout-dashboard.layout-dashboard-rtl .header-control {
    justify-content: flex-end;
  }
}

@media (min-width: 992px) {
  .layout-dashboard .ant-layout-sider.sider-primary {
    /* margin: 20px 0 0 20px;
    height: calc(100vh - 20px); */
    height: 100vh;

    padding: 0;
  }
  .layout-dashboard
    .ant-layout-sider.sider-primary
    > .ant-layout-sider-children {
    padding: 13px 20px;
  }
}

.layout-dashboard .ant-layout-sider.sider-primary .brand span {
  vertical-align: middle;
  margin-left: 7px;
}

.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-submenu
  .ant-menu-item-group-list
  .ant-menu-submenu-title,
.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-submenu
  .ant-menu-item-group-list
  a {
  position: relative;
  margin-left: 4px;
}

.layout-dashboard .ant-layout-sider.sider-primary .aside-footer {
  display: none;
  padding-top: 100px;
  padding-bottom: 33px;
}

.ant-select-multiple .ant-select-selector {
  display: flex;
  flex-wrap: wrap; /* Allow tags to wrap */
  align-items: flex-start; /* Align items at the top */
  padding: 4px; /* Add padding to make it look nice */
  min-height: 40px; /* Ensure a default height */
  height: auto !important; /* Allow height to grow automatically */
}

.ant-select-multiple .ant-select-selection-item {
  margin-bottom: 4px; /* Add spacing between rows of tags */
}

.ant-select-multiple {
  overflow: visible; /* Allow the container to expand with content */
}
